import React from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SeparatorList from 'components/uiLibrary/SeparatorList';
import EntityName from 'components/Globals/EntityName';
// eslint-disable-next-line import/no-cycle
import usePageContext from 'utils/hooks/usePageContext';
import { getComposerName } from 'utils/composer';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { TP, ENTITY_TYPE } from 'constants/index';
import { useTranslation } from 'src/i18n';

import { getWorkTitle } from 'utils/works';
import { getProgramLabel } from 'utils/common';
import classes from './ProductionStubWorks.module.scss';

const ProductionStubWorks = ({ production, showProductionName = false, trackingData = {} }) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const { isMobile } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();
  const { works, name } = production || {};
  return (
    <div className={classes.prodWorksInfo}>
      {!isMobile && (
        <span className={classes.titleWrapper}>
          <Typography variant="p" className={classes.title} weight="medium">
            {t(production?.isProgramNotAnnounced ? getProgramLabel(production, works?.length) : `${TP}.FN_PROGRAM`)}
          </Typography>
          {works?.length > 0 && (
            <Typography variant="p" className={classes.total} color="secondary" weight="medium">
              {works?.length}
            </Typography>
          )}
        </span>
      )}
      <div className={classes.worksWrapper}>
        <div className={classnames(classes.works, { [classes.cancelled]: production?.isCancelled })}>
          {showProductionName && name && <Typography className={classes.prodTitle}>{name}</Typography>}
          {works?.map(work => {
            const linkProps = navigate.getLinkProps({
              entity: work,
              entityType: ENTITY_TYPE.WORK,
            });

            return (
              <div className={classes.worksAndTags} key={work?.id}>
                <div className={classes.work}>
                  <LinkButton
                    {...linkProps}
                    variant="text"
                    styles={{ root: classes.workLink }}
                    disableUnderline
                    isLink
                    inline
                    trackingData={{
                      ...trackingData,
                      entity: work?.id,
                      entityName: work?.name,
                      entityType: ENTITY_TYPE.WORK,
                    }}
                  >
                    <EntityName entityType={ENTITY_TYPE.WORK} entity={work} trackingData={trackingData} withComposers />
                  </LinkButton>

                  {work?.workExcerptReference && (
                    <Typography variant="p" size={12} color="secondary" className={classes.excerpt}>
                      {work?.workExcerptReference}
                    </Typography>
                  )}
                </div>
                <div className={classes.tags}>
                  {work?.workStageTypes?.map((item, index) => (
                    <div key={index} className={classes.tag}>
                      <Typography
                        className={classnames(classes.workTag, {
                          [classes.isBlur]: work?.productionWorkId === -1,
                        })}
                        size={12}
                        weight="medium"
                      >
                        {item?.workType}
                      </Typography>
                      <Typography
                        className={classnames(classes.stageTag, {
                          [classes.isBlur]: work?.productionWorkId === -1,
                        })}
                        size={12}
                      >
                        {item?.stagingType}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductionStubWorks;
